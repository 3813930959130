.header-band{
  padding:30px 0;
  margin:0;
 // background:@brand-color;
  h1{
    background: fade(@brand-color,80%);
    display:inline-block;
    padding:15px 50px 15px 20px;
    color: #fff;
    margin-left:-25px;
  }
}


.header-band.header-with-image{
  position: relative;
  display:block;
  h1,h2,h3{
    color: #fff;
  }
  .container{
    position: relative;
  }
  .header-band-image{
    position: absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    opacity: .7;
    background-size: cover;
  }
}
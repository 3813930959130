.complex-wrapper{
  //background: fade(@brand-grey, 30%);
  h2{
    text-align:center;
    text-transform: uppercase;
    font-size: 1.4rem;
  }
}
.complex-show-title{
 // font-size:1.6rem;
  margin:15px 0;
  text-align: center;
  color: #fff;
}

.complex-show-zone{
  font-size:1rem;
  font-weight: bold;
  text-align: center;
  display:block;
}

.complex-show-description{
  margin-top:30px;
  max-width:1000px;
  text-align: center;
}

.complex-box-delimiter{
  background: #fff;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
}
.complex-properties{
  margin-top:30px;
  h2{
    text-align: center;
  }
}
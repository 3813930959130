.prop-highlight{
  .card-body-adition{
    background: @brand-color2;
  }
}

.property-card {
  //border:none;
  transition: all .4s ease 0s;
 // margin-bottom:20px;
  background-size: cover;
  background-position: center center;
  height:340px;
  border-radius:3px;
  //border: 1px solid rgba(255, 255, 255, 0.35);
  border:1px solid #564F4C;

  //&:before{
  //  transition: all .4s ease 0s;
  //  content: " ";
  //  position: absolute;
  //  display:block;
  //  top:0;
  //  left:0;
  //  bottom:0;
  //  right:0;
  //  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 39.07%, rgba(30, 20, 16, 0.8) 100%);
  //}

  a.image-link{
    position: relative;
  }

  .image-overlay{
    transition: all .6s ease 0s;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 39.07%, rgba(30, 20, 16, 0.9) 100%);
    opacity:70%;
  }

  .propertygrid-meta{
    margin-right:10px;
    position:relative;
    padding-right:10px;

    svg{
      opacity: .5;
    }

    &:before{
      position: absolute;
      right: 0;
      background: rgba(255,255,255,.4);
      height: 20px;
      width: 1px;
      content: '';
      top: 50%;
      transform: translateY(-50%);
    }
    &:last-child{
      &:before{
        display:none;
      }
    }
  }

  &:hover{
    box-shadow: 0 20px 20px rgba(0,0,0, 20%);

    .image-overlay {
      transition: all .6s ease 0s;
      opacity:100%;
    }

  }



  .card-body {
    .price {
      font-size:18px;
      font-weight: 600;
      span{
        font-size:50%;
      }
    }

    .location{
      margin-bottom:5px;
      //font-size:13px;
      //font-weight: bold;
    }
  }

  .card-img, .card-img-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}


.card-body{
  position:absolute;
  bottom:0px;
  left:0px;
  right:0px;
  color: #fff;
  fill:#fff;

  .property-title{
    text-align: center;
    font-size: 14px;
    font-weight: 800;
  }

  .location{
    text-align: center;
    font-size: 13px;
    font-weight: normal;
  }

  .propertygrid-meta-wrapper{
    float:left;
  }

  .price{
    float:right;
  }

}

.card-body-adition{
  //margin-top:5px;
  background:@brand-color;
  height:80px;
  clip-path: ~"polygon(0 0,100% 0,100% calc(100% - 20.00px),calc(100% - 20.00px) 100%,0 100%)";

  .property-title{
    //background:@brand-color;
    font-family: @font-title;
    color:#fff;
    //font-weight: bold;
    padding:15px;
  }
}

.container-with-map{
  padding: 0;
  display: inline-flex;
  height: ~"calc( 100vh - 100px)";
  width: 100%;
}
.map-container{
  width: ~"calc(100% - 750px)";
  height: 100%;
  position: relative;
}

.results-container{
  width:750px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding:10px;
}

.with-map #footer{
  display:none;
}

.leaflet-control-attribution{
  display:none;
}
.btn-primary{
  --bs-btn-bg: @brand-color2;
  --bs-btn-border-color:@brand-color2;
  --bs-btn-hover-border-color: darkern(@brand-color2, 5%);
  --bs-btn-hover-bg: darken(@brand-color2, 10%);
  --bs-btn-active-bg: darken(@brand-color2, 10%);

  svg{
    fill: #fff;
  }
}

.btn-filtering{
  //text-transform: uppercase;
}

.search-form {
  .btn-check:active + .btn, .btn-check:checked + .btn, .btn.active, .btn.show, .btn:active {
    background: @site-properties-search-form-btn-check-bg;
    color:@site-properties-search-form-btn-check-color;
  }
}

.text-short-show-more{
  display:none;
  color: #fff;
  background: @brand-color;
  padding:5px 10px;
  font-size: 12px;
  text-decoration: none;
  border-radius: 5px;
  margin:10px auto;
}
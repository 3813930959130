
.divider-simple{
  padding: 50px 0 50px;
}

.divider-grey{
  padding: 50px 0 50px;
  background: @brand-color;
  color: #fff;
  h1,h2,h3{
    color: #fff;
  }
}
.divider-fullscreen-margin{
  margin:0 15px;
}

.dark-container{
  background: @brand-color;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;

}

.light-container{
  background: #fff;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;

}

.homepage-search{
  padding:300px 0;
}

@media(max-width: @screen-md)
{
  .homepage-search {
    padding: 100px 0;
  }
}
.modal-search {
  --bs-modal-bg: @brand-color;
  --bs-modal-header-border-color:rgba(255,255,255,.1);
  --bs-modal-footer-border-color:rgba(255,255,255,.1);

  h3 {
    color: @brand-color2;
  }

  .btn-close {
    filter: invert(1) grayscale(100%) brightness(200%);
  }

  .search-form-wrapper {
    padding: 0;
  }

  .search-item {
    margin-top: 25px;
  }

  .form-group-picker {
    width: 100%;
  }

  .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 100%;
  }

  .search-inner {
    .form-group-picker .btn, .form-control {
      background: @site-properties-search-form-input-background;
      color: @site-properties-search-form-input-color;
    }
    .form-control{
      border:@site-properties-search-form-input-border;
    }

    .btn-search-item{
      border:@site-properties-search-form-input-border;
      //background: rgba(255, 255, 255, .1);
    }
  }
}


.shortsearchform{
  &.search-form-wrapper{
    .icon svg{
      fill: #fff;
    }
  }
  input{
    color:#fff;
  }
  input:focus{
    border: none;
    color:#fff;
  }
  input::placeholder{
    color: #fff;
  }
}
.search-form{
  label{
    color: #fff;
  }

  .search-item{
    margin-top:10px;
  }

  .search-inner .btn-search{
    width:50px;
    height: 50px;
    border-radius: 9999px;
    display:block;
    padding:10px;
    min-width: 0;
    background: #fff;
    border:none;
    svg{
      fill: #000;
    }
  }



}


.shortsearchform.search-form-wrapper {
  border-radius: @brand-radius;
  position: relative;
  background: fade(@brand-color, 40%);
  border: 1px solid rgba(255, 255, 255, .5);
  padding: 30px;
  //-webkit-box-shadow: inset 0px 0px 36px 0px fade(@brand-color,70%);
  //box-shadow: inset 0px 0px 36px 0px #000000 fade(@brand-color,70%);
  ////clip-path: polygon(0 30.00px,30.00px 0,100% 0,100% 100%,0 100%);

  //    clip-path: polygon(0 40.00px,40.00px 0,calc(100% - 40.00px) 0,100% 40.00px,100% calc(100% - 40.00px),calc(100% - 40.00px) 100%,40.00px 100%,0 calc(100% - 40.00px));

  .icon {

    svg {
      fill: #fff !important;
      width: 20px;
      height: 20px;
    }
  }


}


.search-inner{
  display: flex;
  flex-flow: row wrap;
  position: relative;

  .form-group-picker{
    //width:200px;
    position:relative;

    .icon{
      position:absolute;
      top:5px;
      left:10px;
    }
  }

  .bootstrap-select{

  }


  .dropdown-menu{
    --bs-dropdown-bg: @brand-color;
    --bs-dropdown-color: #fff;
    --bs-dropdown-link-color: #fff;
    --bs-dropdown-link-hover-bg: rgba(255,255,255,.1);
    --bs-dropdown-link-hover-color: #fff;
  }

  .form-group-picker .btn{
    padding-left:40px;
    padding-right:10px;
    border:none;
    background: none;
    color: #fff;
    outline:none !important;
    box-shadow:none;
    // border-radius:0;
    &.show{
      background:rgba(255,255,255,.1);
    }
  }
  .form-control{
    background: none;
  }

  .btn-search{
    background: none;
    border:1px solid @brand-color2;
    border-radius:5px;
    color: #fff;
    padding:10px 30px;

    &:hover{
      border:1px solid darken(@brand-color2,30%);
      color:#fff;
      background: @brand-color2;
    }
    svg{
      width:20px;
      height: 20px;
      fill: #fff;
      margin-right:5px;
    }
  }
}


.short-search-line{
  height:50px;
  width:1px;
  display:block;
  border-left:1px solid rgba(255,255,255,.3);
  margin:0 10px;
}

@media(max-width: @screen-md)
{
  .short-search-line{
    display:none;
  }

  .btn-search{
    min-width:200px;
  }
}


@media(max-width: @screen-xs)
{
  .search-form {
    .btn-search {
      width: 100%;
      margin-top: 20px;
    }

    .form-group-picker {
      width: 100%;
      margin-top: 20px;
    }

    .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
      width: 100%;
    }
  }
}
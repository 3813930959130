
.property-short-caracteristics-wrapper{
  background: fade(@brand-color,80%);
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
}
.property-show-short-features{
  display: flex;
}
.property-show-short-feature-block{
  //width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  //display:flex;
}

.property-show-short-feature-block-title {
  margin-bottom: 4px;
  color: hsla(0, 0%, 100%, 0.75);
}

.property-show-short-feature-block-values{
  display: flex;
  padding-top: 4px;
  padding-bottom: 4px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-right: 2px solid hsla(0, 0%, 100%, 0.3);
  color: #fff;
}

.property-show-short-feature-block-value{
  font-size: ~"calc(0.8rem + 0.7vw)";
  line-height: 30px;
  font-weight: 800;
}

.property-show-short-feature-block:last-of-type{
  .property-show-short-feature-block-values{
    border-right:none;
  }
}

.property-show-short-feature-block-icon svg{
  width:24px;
  height:24px;
  margin-right:10px;
  fill:#fff;
  opacity:.5;
}
.fullpage-slideshow{
  position: absolute;
  top: 0;
  z-index: -1;
  width:100%;
  overflow:hidden;

  .slideshow-item{
    min-height: 100vh;
    &:after{
      display:block;
      content:" ";
      position:absolute;
      top:0;
      left:0;
      bottom:0;
      right:0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(3, 2, 2, 0) 24.83%, rgba(8, 6, 4, 0) 47.22%, rgba(15, 10, 8, 0.498264) 69.62%, @brand-color 100%);
    }
  }
}


.property-main-gallery, .complex-main-gallery{
  background:fade(@brand-color,80%);
  padding:20px;
}

#property-main-gallery-pager{
  display:none;
}

.property-main-gallery-item, .complex-main-gallery-item{

  display:flex;
  align-items: center;
  justify-content: center;

  img{
    width:auto !important;
    margin:0 auto;
    max-width: 100%;
    height: auto;
    max-height:600px;
  }
}



.owl-nav{
  position:absolute;
  top:50%;
  left:0;
  right:0;

  .owl-next{
    position:absolute;
    right:10px;
  }
  .owl-prev{
    position:absolute;
    left:10px;
  }

  .owl-next, .owl-prev{

    border-radius: 999px !important;
    border: 2px solid #fff !important;
    display: block;
    width:40px;
    height: 40px;
    line-height:40px;
    box-shadow: rgba(0,0, 0,  0.5) 0px 2px 3px;
    &:hover{
      background: fade(@brand-color, 50) !important;
    }
    span {
      font-size: 40px;
      line-height:30px;
    }

  }
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 20px;
}

@media(max-width: @screen-sm)
{
  .property-main-gallery-item, .complex-main-gallery-item{

    img{

      max-height:350px;
    }
  }

}
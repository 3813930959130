header{
  z-index: 20;
  min-height: 150px;
}
.logo{
  max-height: 170px;
  padding:30px;
}

@media(max-width: @screen-md)
{
  header{
    height: 80px;
  }
  .logo{
    padding:5px;
    margin-left:20px;
    max-height: 80px;
  }
}
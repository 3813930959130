.complexes-wrapper{
  padding-top:30px;

}

.complex-list-box{
  transition: all .4s ease 0s;
  display:flex;
  //gap: 20px;
  border-radius:10px;
  overflow: hidden;
  //padding:0;
  margin-bottom:30px;
  background: fade(@brand-grey, 80%);
  position: relative;

  .complex-list-box-image{
    img {
      width: 250px;
    }
  }
 // box-shadow: 0 0 20px rgba(0,0,0, 5%);

  &:hover{
    box-shadow: 0 5px 20px rgba(0,0,0, 20%);
  }


  .property-row-content{
    padding:15px;
  }

  .complex-list-box-title{
    font-size:22px;
    color:@brand-color;
    margin-bottom: 10px;
    margin-top:5px;

    a{
      color:#fff;
      text-decoration: none;
    }
  }

  .complex-list-box-location{
    font-size:14px;
    svg{
      fill:#fff;
    }
  }
}

.complex-list-box-body{
  width:100%;
  display:flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top:25px;

  .complex-list-box-meta{
   // background: fade(@brand-color, 80%);
    background: @brand-color;
    font-weight: bold;
    font-size: 12px;
    color: #fff;
    padding:5px 8px;
    border-radius:5px;
    svg{
      fill: #fff;
    }
  }
}

@media(max-width: @screen-xs)
{
  .complex-list-box {
    .complex-list-box-image {
      img {
        width: 100%;

      }
    }
  }
  .complex-list-box {
    flex-direction: column;
  }
}
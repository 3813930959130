body {
  -webkit-font-smoothing: antialiased;
  // font-size: 15px;
  font-size: 0.9rem;
  font-family: @font-main;
  background-color: @body-bg-color;
  line-height: 160%;
  min-height: 100%;
  color: @body-color;
}

a:hover{
  color: @brand-color2;
}

h1,h2,h3{
  font-family: @font-title;
  font-weight: 600;
  color: #303030;
}

h1{
  // font-size: 2.7rem;
  margin:30px 0;
}

h2{
  margin:20px 0;
}

h1.container-title,h2.container-title{
  text-align: center;
  color: #fff;
  span{
    color:@brand-color2;
  }

}
.center-title h1, .center-title h2{

}

.border-8{
  padding:8px !important;
}
.property-wrapper{
  //background: var(--bs-gray-100);
 // background: fade(@brand-grey, 30%);
  h1,h2,h3{
    text-transform: uppercase;
  }
}
.property-show-title{
  font-size:1.6rem;
  margin:15px 0;
}

.property-show-title{
  color: #fff;
}

.property-show-caracteristics-group{
  --bs-list-group-bg: fade(@brand-color, 80%);
  --bs-list-group-color: #fff;
}

.property-show-price{
  padding:15px 30px;
  margin:15px 0;
  color: #fff;
  display:block;
  font-size: 1.3rem;
  font-weight: bold;
  background: @brand-color2;
  border-radius: 0.375rem;
  span{
    font-size:50%;
  }
}

.property-show-zone{
  font-size:1rem;
  font-weight: bold;
}

.property-wrapper h2{
  font-size:1.4rem;
  color: #fff;
}

.property-wrapper h5{
  font-size:1rem;
  font-weight:bold;
}

.property-show-caracteristics-group{
  .list-group-item {
    //background: @light-grey;
    border-left:none;
    border-right:none;
    border-top:none;

    span{
     font-weight: bold;
      margin-left:10px;
      float:right;
    }
  }
}

.property-box-delimiter{
  background: fade(@brand-color, 80%);
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
}

.spec-item{
  line-height: 180%;
  color: #212529;
  padding-bottom:10px;

  span{
  background:@brand-color;
    color: #fff;
    padding:0.5rem 1rem 0.5rem 0.5rem;

    border-radius: 0.375rem;
    svg {
      fill: #fff;
      opacity:.8;
    }
  }

}

#window-map{
  height: 300px;
}


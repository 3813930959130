::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 500;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-weight: 500;
}

::-ms-input-placeholder { /* Microsoft Edge */
  font-weight: 500;
}

.form-label, label{
  font-weight: 700;
}

.form-group{
  margin-bottom: 15px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;

  height: 48px;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #f5f8fa;
  -webkit-transition: border-color 200ms ease;
  transition: border-color 200ms ease;
  font-weight: 500;
}

.input-group-text{
  background-color: darken(#f5f8fa,10%);
  border: 2px solid transparent;
}

.has-error input{
  border: solid 2px red !important;
}


textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.form-control:focus,
.uneditable-input:focus {
  border-color: @form-form-control-focus-border;
  box-shadow: none;
  outline: 0 none;
}

textarea.form-control{
  height:auto;
}

.btn{
  //margin-top: 16px;
  //margin-right: 16px;
  padding: 10px 24px;
  border-style: solid;
  //border-width: 2px;
  //border-color: @brand-color;
  border-radius: 4px;
  //background-color: @brand-color;
  -webkit-transition: border-color 200ms ease, color 200ms ease, background-color 200ms ease;
  transition: border-color 200ms ease, color 200ms ease, background-color 200ms ease;
  //color: #fff;
  font-size: 16px;
  line-height: 22px;
 // font-weight: 700;
  letter-spacing: 0.6px;
  text-decoration: none;
  //text-transform: capitalize;
  //cursor: pointer;

  &:hover{
  //  background: #282e38;
  //  border-color: #282e38;
   // color: #fff;
  }
}

.btn-form{
  background-color: @brand-color;
  color: #fff;
  text-transform: capitalize;
  font-weight: 700;
}

.btn-form-right{
  margin-left:auto !important;
}

.input-group-text{
  //background: rgba(0,0,0,0.4);
 svg {
   //fill: #fff;
   //fill: @form-form-control-focus-border;
 }
}
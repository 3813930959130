.about{
  padding-left:70px;
}

.page-wrapper{
  color: #fff;
  h1,h2,h3,a,p{
    color: #fff;
  }
  background: fade(@brand-color, 80%);
}
@media(max-width: @screen-md)
{
  .about{
    padding-left:0;
  }
}